
import { Component, Prop, PropSync, Vue, Watch } from 'vue-property-decorator';

import { FuelMetricsTank, Product } from '@/store/models/Product';

import VolumeProgressBar from '@/components/items/VolumeProgressBar.vue';
import FixedProgressBar from '@/components/items/FixedProgressBar.vue';

import CustomField from '@/store/models/CustomField';
import TableColumn from '@/store/models/TableColumn';

@Component({
  components: {
    FixedProgressBar
  }
})
export default class GenericCardView extends Vue {
  @PropSync('items') sortedProducts!: Product[];
  @Prop() maxTank!: number;
  @Prop() columns!: TableColumn[];
  @Prop() customColumns!: TableColumn[];
  @Prop() customFieldDef!: CustomField[];
  @Prop() customEntityColumns!: TableColumn[];
  @Prop() customEntityFieldDef!: CustomField[];
  @Prop() loadingProducts!: boolean;

  public didSelectCard(selectedItems: Product[], id: number, vatId: number) {
    this.$emit('card-selected', selectedItems, id, vatId);
  }

  public checkInFields(field: string) {
    const filtered = this.columns.filter(column => column.name == field);
    if (filtered.length != 0) {
      return filtered[0].visible;
    } else {
      return false;
    }
  }
}
