/**
 * Given a `rawSvgString` like `<svg>...</svg>`, return its URI-encoded representation.
 * @param {string} rawSvgString
 */
export function encodeSVG(rawSvgString: string): string {
  const symbols = /[\r\n%#()<>?[\\\]^`{|}]/g;

  // Use single quotes instead of double to avoid URI encoding
  rawSvgString = rawSvgString
    .replace(/'/g, '"')
    .replace(/>\s+</g, '><')
    .replace(/\s{2,}/g, ' ');

  return (
    'data:image/svg+xml;utf-8,' +
    rawSvgString.replace(symbols, encodeURIComponent)
  );
}

export function getImage(file: string) {
  return require(`../assets/${file}.svg`) as string;
}
