
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Product, FuelMetricsTank } from '@/store/models/Product';
import ProductStore from '@/store/modules/ProductStore';
import { trackEvent } from '@/services/Mixpanel';
import { setListItem } from '@/utils/LocalStorageUtils';

@Component
export default class GenericSideContent extends Vue {
  @Prop() selectedEntityState!: Product;
  @Prop() selected!: boolean;
  @Prop() tankIndex!: number;
  @Prop() width!: number;
  public tabIndex = 0;

  public partnerDefinedName = '';
  public partnerDefinedId = '';

  get currentFuelTank(): Product | null {
    if (
      this.selectedEntityState &&
      this.selectedEntityState.entities &&
      this.tankIndex != -1
    ) {
      return this.selectedEntityState.entities[this.tankIndex];
    } else {
      return null;
    }
  }

  public isInRoute() {
    if (this.selectedEntityState) {
      const checked = [...(ProductStore.currentRoute.generic ?? [])];
      const checkedName =
        'productChecked-' +
        this.selectedEntityState.productId +
        '-' +
        this.currentFuelTank?.entityId;
      return checked.includes(checkedName);
    } else {
      return false;
    }
  }

  public addToRoute() {
    let checked = [...(ProductStore.currentRoute.generic ?? [])];
    if (!this.isInRoute()) {
      checked.push(
        'productChecked-' +
          this.selectedEntityState.productId +
          '-' +
          this.currentFuelTank?.entityId
      );
      ProductStore.updateRouteState({ generic: checked });
      setListItem('genericRoute', checked);

      this.$bvToast.toast(
        `${this.getNameOfProduct(
          this.selectedEntityState.productId,
          this.currentFuelTank?.entityId
        )} has been added to your route`,
        {
          title: 'Route updated',
          toaster: 'b-toaster-bottom-center',
          solid: true,
          append: false,
          to: '/generic-route-plan'
        }
      );
      this.$root.$emit('routeUpdated');
    } else {
      checked = checked.filter(
        item =>
          !(
            item ==
            'productChecked-' +
              this.selectedEntityState.productId +
              '-' +
              this.currentFuelTank?.entityId
          )
      );
      ProductStore.updateRouteState({ generic: checked });
      setListItem('genericRoute', checked);

      this.$bvToast.toast(
        `${this.getNameOfProduct(
          this.selectedEntityState.productId,
          this.currentFuelTank?.entityId
        )} has been removed from your route`,
        {
          title: 'Route Updated',
          toaster: 'b-toaster-bottom-center',
          solid: true,
          append: false,
          to: '/generic-route-plan'
        }
      );
      this.$root.$emit('routeUpdated');
    }
  }

  public getNameOfProduct(productId: number, entityId: number | undefined) {
    const item = ProductStore.currentProducts?.filter(
      (product: Product) =>
        product.productId == +productId && product.entityId == entityId
    );
    const name = item ? item[0].siteName : 'Fuel tank';
    const tank = item
      ? ` ${(item[0].metrics as FuelMetricsTank).fuelType ?? ''} ${(item[0]
          .metrics as FuelMetricsTank).nominalVolume ?? ''}L`
      : '';
    return name + ' - ' + tank;
  }

  public nextTank() {
    this.tankIndex =
      this.tankIndex == (this.selectedEntityState.entities?.length ?? 1) - 1
        ? 0
        : this.tankIndex + 1;
  }

  public prevTank() {
    this.tankIndex =
      this.tankIndex == 0
        ? (this.selectedEntityState.entities?.length ?? 1) - 1
        : this.tankIndex - 1;
  }

  public specificTank(index: number) {
    this.tankIndex = index;
  }
}
