
import DraggablePanel from '@/components/buttons/DraggablePanel.vue';
import GenericSideContent from '@/components/GenericSideContent.vue';
import GoogleMap from '@/components/GoogleMap.vue';
import GenericListView from '@/components/list/GenericListView.vue';
import TableSearchbar from '@/components/table/TableSearchbar.vue';
import BoundingBox from '@/services/api/models/BoundingBox';
import { Product } from '@/store/models/Product';
import ProductStore from '@/store/modules/ProductStore';
import User from '@/store/modules/User';
import { groupProducts } from '@/utils/formatters/EntitiesFormatter';
import { getObjectItem } from '@/utils/LocalStorageUtils';
import { isAdmin } from '@/utils/TestingValidity';
import { Component, Ref, Vue, Watch } from 'vue-property-decorator';

@Component({
  components: {
    GenericListView,
    TableSearchbar,
    GoogleMap,
    GenericSideContent,
    DraggablePanel
  },
  methods: {
    isAdmin
  },
  computed: {
    allProducts: () => ProductStore.currentProducts ?? []
  },
  watch: {
    $route: function() {
      this.$root.$emit('pageChanged');
    }
  }
})
export default class GenericProductView extends Vue {
  @Ref('map') readonly googleMap!: GoogleMap;
  public boundingBox: BoundingBox = {
    southWest: { lat: -46.42390503374751, lon: 140.32829142386333 },
    northEast: { lat: -29.536734701401574, lon: 180 }
  };
  public allProductBoundingBox: BoundingBox = {
    northEast: { lat: -31.984373049797064, lon: 180 },
    southWest: { lat: -48.599905655011945, lon: 153.56366313946495 }
  };
  public collapseMap = false;
  public products: Product[] = [];
  public tanks: Product[] = [];
  public sortBy = 'siteName';
  public searchTerm = '';
  private firstLoad = true;
  private selectedProductId: number | null = null;
  public entityState: Product | null = null;
  public increasing = true;
  public loading = true;
  public selected = false;
  public tankId = -1;
  public mapHeight = 500;
  public prevMapHeight = 500;
  public width = 1000;
  public isFilterDropVisible = false;
  public loadingProducts = true;
  public paginatedProducts: Product[] = [];
  public offset = 0;
  public perPageLimit = 25;
  public routePlanProducts: number[] = [];
  public routePlanEntities: number[] = [];
  public updatedFilters = false;
  public filterByMap = false;
  public activeSearch = false;
  public isRouteView = false;

  get selectedProduct() {
    if (this.selectedProductId) {
      return this.products.find(
        product => product.productId === this.selectedProductId
      );
    }
    return null;
  }

  mounted() {
    this.getPage();
    this.updateWidth();
    if (window.innerWidth < 1000) {
      this.prevMapHeight = window.innerHeight - 125;
    } else {
      this.mapHeight = window.innerHeight / 2;
      this.prevMapHeight = this.mapHeight;
    }
    this.routePlanProducts =
      ProductStore.currentRoute.generic?.map(item => {
        return +item.split('-')[1];
      }) ?? [];
    this.routePlanEntities =
      ProductStore.currentRoute.generic?.map(item => {
        return +item.split('-')[2];
      }) ?? [];
    window.addEventListener('resize', this.updateWidth);
    window.addEventListener('pushstate', this.getPage);
    this.$root.$on('bv::dropdown::show', (bvEvent: any) => {
      if (
        bvEvent.componentId === 'typeDrop' ||
        bvEvent.componentId === 'volDrop' ||
        bvEvent.componentId === 'capDrop' ||
        bvEvent.componentId === 'sortDrop' ||
        bvEvent.componentId === 'xlsxDrop'
      ) {
        this.isFilterDropVisible = true;
      }
    });
    this.$root.$on('bv::dropdown::hide', (bvEvent: any) => {
      if (
        bvEvent.componentId === 'typeDrop' ||
        bvEvent.componentId === 'volDrop' ||
        bvEvent.componentId === 'capDrop' ||
        bvEvent.componentId === 'sortDrop' ||
        bvEvent.componentId === 'xlsxDrop'
      ) {
        this.isFilterDropVisible = false;
      }
      if (this.isFilterDropVisible) {
        bvEvent.preventDefault();
      }
    });
    this.loadSavedPreferences();
    this.loadAllEntities();
  }

  created() {
    this.$root.$on('sortByUpdated', (sortKey: string) => {
      this.updateSortBy(sortKey);
    });
    this.$root.$on('orgIndexChanged', () => {
      this.fetchEntities(this.boundingBox);
      this.loadAllEntities();
    });
    this.$root.$on('pageChanged', () => {
      this.getPage();
      this.filterProducts();
    });
    this.$root.$on('routeUpdated', () => {
      this.routePlanProducts =
        ProductStore.currentRoute.generic?.map(item => {
          return +item.split('-')[1];
        }) ?? [];
      this.routePlanEntities =
        ProductStore.currentRoute.generic?.map(item => {
          return +item.split('-')[2];
        }) ?? [];
    });
  }

  destroyed() {
    window.removeEventListener('resize', this.updateWidth);
    window.removeEventListener('pushstate', this.getPage);
  }

  public loadSavedPreferences() {
    if (this.width > 1000) {
      const mapHeight = getObjectItem('genericMapHeight');
      if (mapHeight) {
        this.mapHeight = mapHeight;
        this.prevMapHeight = this.mapHeight;
      }
    }
  }

  public getPage() {
    const route = this.$route.path;
    if (route.includes('view')) {
      this.isRouteView = false;
    } else if (route.includes('route-plan')) {
      this.isRouteView = true;
    }
  }

  @Watch('products')
  productsUpdated() {
    this.offset = 0;
    this.updatePaginatedProducts();
  }

  @Watch('paginatedProducts')
  paginatedProductsUpdated() {
    this.updateMap();
  }

  @Watch('filters', { deep: true })
  handleFilter() {
    this.filterProducts();
  }

  @Watch('selectedProductId')
  getEntityState() {
    this.loading = true;
    if (this.selectedProductId) {
      const selectedProduct = this.products.find(
        product => product.productId === this.selectedProductId
      );
      if (selectedProduct) {
        this.entityState = selectedProduct;
      } else {
        this.entityState = null;
      }
    } else {
      this.entityState = null;
    }
    setTimeout(() => {
      this.updateWidth();
    }, 500);
  }

  public updateWidth() {
    let changeToSmallScreen = false;
    let changeToWideScreen;
    if (this.width >= 1000 && window.innerWidth < 1000) {
      changeToSmallScreen = true;
    }
    if (this.width < 1000 && window.innerWidth >= 1000) {
      changeToWideScreen = true;
    }
    if (changeToSmallScreen) {
      this.collapseMap = true;
      this.prevMapHeight = window.innerHeight - 125;
      this.mapHeight = 0;
    } else if (changeToWideScreen) {
      this.prevMapHeight = window.innerHeight / 2;
      const mapHeight = getObjectItem('genericMapHeight');
      if (mapHeight) {
        this.prevMapHeight = mapHeight;
      }
    }
    this.width = window.innerWidth;

    if (this.width < 1000 && !this.collapseMap) {
      this.mapHeight = window.innerHeight - 125;
    } else if (this.width >= 1000 && !this.collapseMap) {
      this.mapHeight = this.prevMapHeight;
    }
  }

  // Called when map pans / zooms - it emits mapDidChange event.
  // NOTE: this also fires when the map loads for the first time.
  public mapDidChangeHandler(boundingBox: BoundingBox): void {
    // this.fetchProducts(boundingBox);
    if (boundingBox.southWest.lat == boundingBox.northEast.lat) {
      this.fetchEntities(this.boundingBox);
    } else {
      this.boundingBox = boundingBox;
      this.fetchEntities(boundingBox);
    }
  }

  private fetchEntities(boundingBox?: BoundingBox): void {
    this.loadingProducts = true;
    ProductStore.fetchEntityStates({
      partnerId: User._token?.orgs[User._orgIndex].orgId ?? 0,
      productRequest: {
        boundingBox,
        productType: 'generic' // This should take productType as a param to generalise to one view.
      }
    }).then(async products => {
      this.tanks = products;
      const groupedByProductID = groupProducts(products);

      this.products = groupedByProductID;
      this.filterProducts();

      this.updatePaginatedProducts();
      this.loadingProducts = false;
    });
  }

  private loadAllEntities(): void {
    this.loadingProducts = true;
    ProductStore.fetchAllEntityStates('water');
    this.loadingProducts = false;
  }

  public updatePaginatedProducts() {
    this.paginatedProducts = this.products.splice(
      this.offset,
      this.perPageLimit
    );
  }

  public resetMap(): void {
    this.loading = true;
    this.mapHeight =
      this.width < 1000 ? this.mapHeight : window.innerHeight / 2;
    this.boundingBox = this.allProductBoundingBox;
    this.filterByMap = false;
    setTimeout(() => {
      this.$root.$emit('updateListSize');
      this.$root.$emit('resetMap');
      this.fetchEntities(this.allProductBoundingBox);
      this.loading = false;
    }, 500);
  }

  public updateMap() {
    // Can we do this a better way?
    // Should some of this be contained in the map component?
    if (this.firstLoad && this.products.length) {
      this.firstLoad = false;
      this.paginatedProducts.forEach(product => {
        this.addMarkerForProduct(product);
      });

      //this.googleMap.zoomToMarkers();
    } else {
      const allMarkerIds = Object.keys(this.googleMap.markers);
      allMarkerIds.forEach(markerId => {
        if (
          !this.paginatedProducts.find(
            product => product.productId === parseInt(markerId, 10)
          )
        ) {
          // This marker is not in the subset of products. Remove it.
          this.googleMap.markers[parseInt(markerId, 10)].setMap(null);
          delete this.googleMap.markers[parseInt(markerId, 10)];
        }
      });
      this.paginatedProducts.forEach(product => {
        if (
          !allMarkerIds.find(
            markerId => product.productId === parseInt(markerId, 10)
          )
        ) {
          // We have a product without a marker.
          this.addMarkerForProduct(product);
        } else if (product.productId === this.selectedProductId) {
          this.setMarkerSelected(product.productId, true);
        } else {
          // We have a marker for this product, but it is not selected
          this.setMarkerSelected(product.productId, false);
        }
      });
    }
  }

  public productSelectedFromList(
    selectedProducts: Product[],
    id: number
  ): void {
    if (selectedProducts.length) {
      // We only allow single selection, so grab the first item from array.
      // Multiple selections will be a thing some time soon.
      const product = selectedProducts[0];
      const prevSelect = this.selectedProductId;
      const prevTankSelect = this.tankId;
      this.tankId = id;
      if (prevSelect === product.productId && prevTankSelect === id) {
        this.selected = false;
        this.selectedProductId = null;
      } else {
        this.selected = true;
        this.selectedProductId = product.productId;
      }
      this.updateMap();
    }
  }

  public search(searchTerm: string): void {
    this.searchTerm = searchTerm.toString();
    this.filterProducts();
  }

  private addMarkerForProduct(product: Product): void {
    const days = Math.round(this.getMinDays(product.entities).minDays ?? 0);
    this.googleMap.addMarker({
      lat: parseFloat(product.latitude),
      lon: parseFloat(product.longitude),
      tag: product.productId,
      markerVal: (days > 365 ? '>365' : days) + 'd',
      supplyNum: '0',
      isSelected: product.productId === this.selectedProductId,
      mapTagValue: ''
    });
  }

  private setMarkerSelected(index: number, isSelected: boolean): void {
    if (this.googleMap.markers[index]) {
      this.googleMap.markers[index].setIcon(
        this.googleMap.getMarker({ isSelected })
      );
    }
    if (isSelected) {
      this.googleMap.setDetailCard(this.googleMap.markers[index]);
    }
  }

  // Called when a map marker is clicked - lets have this logic somewhere else, reusable.
  public mapDidSelectMarkerHandler(productId: number): void {
    const prevSelect = this.selectedProductId;
    if (prevSelect) {
      this.setMarkerSelected(prevSelect, false);
    }
    if (prevSelect === productId) {
      this.selected = false;
      this.selectedProductId = null;
    } else {
      this.selected = true;
      this.selectedProductId = productId;
    }

    this.tankId = this.getMinDays(this.selectedProduct?.entities).index;

    if (this.selectedProductId) {
      this.setMarkerSelected(this.selectedProductId, true);

      (this.$refs.productList as GenericListView).selectCard(
        this.selectedProductId + '/' + this.tankId
      );
    }
  }

  public filterProducts() {
    // filter on search term
    let filtered = ProductStore.currentProducts;
    if (filtered) {
      if (this.isRouteView) {
        filtered = filtered.filter(product => {
          const entityId = product.entityId ?? '';
          return (
            this.routePlanProducts.includes(product.productId) &&
            this.routePlanEntities.includes(+entityId)
          );
        });
      }

      filtered = filtered.filter(product => {
        if (
          product.siteName
            ?.toLowerCase()
            .indexOf(this.searchTerm.toLowerCase()) !== -1 ||
          (product.partnerDefinedId &&
            product.partnerDefinedId?.indexOf(this.searchTerm.toLowerCase()) !==
              -1)
        ) {
          return true;
        } else {
          return false;
        }
      });

      this.tanks = filtered;
      this.products = groupProducts(filtered);
    }
  }

  public updateSortBy(sorter: string) {
    if (sorter == 'alert') {
      this.sortBy = sorter;
      this.increasing = false;
    } else {
      if (sorter == this.sortBy) {
        this.increasing = !this.increasing;
      } else {
        this.sortBy = sorter;
        this.increasing = true;
      }
    }

    this.offset = 0;
    this.updatePaginatedProducts();
  }

  public updateSortDesc(newVal: boolean) {
    this.increasing = !newVal;
  }

  public getMinDays(tanksList: any): { minDays: number; index: number } {
    let minIndex = 0;
    let minDays = 10000;
    if (tanksList) {
      const min = tanksList.reduce(function(prev: any, curr: any, index: any) {
        if (prev.metrics.daysTillEmpty > curr.metrics.daysTillEmpty) {
          minIndex = index;
        }
        return prev.metrics.daysTillEmpty < curr.metrics.daysTillEmpty
          ? prev
          : curr;
      });
      minDays = min.metrics.daysTillEmpty;
    }
    return { minDays: minDays, index: minIndex };
  }

  public updateFilterByMap(value: boolean) {
    this.filterByMap = value;
  }

  public dragMapHeight(dy: any) {
    const contain = document.getElementById('mapContainer');
    if (contain) {
      this.mapHeight = +(this.mapHeight - dy);
    }
  }

  public hideMap() {
    this.activeSearch = false;
    if (!this.collapseMap && this.mapHeight > 0) {
      this.collapseMap = true;

      this.prevMapHeight = this.mapHeight;
      this.mapHeight = 0;
    } else {
      this.collapseMap = false;
      if (this.prevMapHeight <= 0) {
        this.prevMapHeight = 500;
      }
      this.mapHeight = this.prevMapHeight;
    }
  }

  public changeOffset(isPrevious: boolean, exactPage: number) {
    if (isPrevious == null) {
      this.offset = exactPage * this.perPageLimit;
    } else {
      this.offset += isPrevious ? -this.perPageLimit : this.perPageLimit;
    }
    this.updatePaginatedProducts();
  }

  public closeSidebar() {
    this.selected = false;
    (this.$refs.productList as GenericListView).selectCard('');
    this.selectedProductId = null;
  }

  public changePerPage(number: number) {
    this.perPageLimit = number;
    this.offset = 0;
    this.updatePaginatedProducts();
  }

  public activateSearch() {
    this.activeSearch = true;
  }

  public unactivateSearch() {
    this.searchTerm = '';
    this.activeSearch = false;
    this.filterProducts();
  }
}
