
import { getFormattedFeedAmount } from '@/utils/FeedUtils';
import { mixins } from 'vue-class-component';
import { Component, Prop, Vue } from 'vue-property-decorator';
import AppName from '../mixin/AppName.vue';

@Component({ methods: { getFormattedFeedAmount } })
export default class FixedProgressBar extends mixins(AppName) {
  @Prop() volume!: string;
  @Prop() capacity!: string;
  @Prop() colour!: string;
  @Prop() safeFill!: number;

  get title() {
    let title = `Capacity: ${getFormattedFeedAmount(
      +this.capacity,
      undefined,
      true
    )}`;
    if (this.safeFill < +this.capacity) {
      title += ` | Safe Fill: ${getFormattedFeedAmount(
        this.safeFill,
        undefined,
        true
      )}`;
    }
    return title;
  }
}
