
import { Component, Prop, PropSync, Vue, Watch } from 'vue-property-decorator';

import { FuelMetricsTank, Product } from '@/store/models/Product';
import ProductStore from '@/store/modules/ProductStore';

import { getListItem, setListItem } from '@/utils/LocalStorageUtils';

@Component
export default class GenericTableList extends Vue {
  @PropSync('items') sortedProducts!: Product[];
  @Prop() active!: number;
  @Prop() maxTank!: number;
  @Prop() loadingProducts!: boolean;
  @Prop() sortByKey!: string;
  @Prop() increasing!: boolean;

  public checked: string[] = [];
  public tableWidth = 1220;
  public allFilteredSelected = false;
  public updatedByAllSelector = false;

  mounted() {
    this.checked = ProductStore.currentRoute.generic ?? [];
    if (this.checked.length == 0) {
      this.checked = getListItem('genericRoute');
      ProductStore.updateRouteState({ generic: this.checked });
    }
  }

  created() {
    this.$root.$on('routeUpdated', () => {
      this.checked = [...(ProductStore.currentRoute.generic ?? [])];
      if (this.checked.length == 0) {
        this.checked = getListItem('genericRoute');
        ProductStore.updateRouteState({ generic: this.checked });
      }
    });
  }

  @Watch('sortedProducts')
  productsUpdated() {
    this.allFilteredSelected = false;
  }

  @Watch('checked')
  routeUpdated() {
    if (!this.updatedByAllSelector) {
      const add =
        this.checked.filter(
          item => !ProductStore.currentRoute.generic?.includes(item)
        ) ?? [];
      const remove =
        ProductStore.currentRoute.generic?.filter(
          item => !this.checked.includes(item)
        ) ?? [];
      if (add.length != 0 || remove.length != 0) {
        const updatedValue =
          add.length != 0
            ? `${this.getNameOfProduct(add[0])} has been added to your route`
            : `${this.getNameOfProduct(
                remove[0]
              )} has been removed from your route`;
        ProductStore.updateRouteState({ generic: this.checked });
        setListItem('genericRoute', this.checked);
        this.$root.$emit('routeUpdated');

        this.$bvToast.toast(updatedValue, {
          title: 'Route updated',
          toaster: 'b-toaster-bottom-center',
          solid: true,
          append: false,
          to: '/generic-route-plan'
        });
      }
    }
  }

  public getNameOfProduct(productChecked: string) {
    const productId = productChecked.split('-')[1];
    const entityId = productChecked.split('-')[2];
    const item = ProductStore.currentProducts?.filter(
      (product: Product) =>
        product.productId == +productId && product.entityId == +entityId
    );
    const name = item ? item[0].siteName : 'Fuel tank';
    const tank = item
      ? ` ${(item[0].metrics as FuelMetricsTank).fuelType} ${
          (item[0].metrics as FuelMetricsTank).nominalVolume
        }L`
      : '';
    return name + ' - ' + tank;
  }

  public didSelectCard(selectedItems: Product[], id: number, tankId: number) {
    this.$emit('row-selected', selectedItems, id, tankId);
  }

  public sortBy(sort: string) {
    this.$root.$emit('sortByUpdated', sort);
  }

  public getTankName(tankName: string) {
    if (tankName) {
      return tankName.length < 30 ? tankName : tankName.substr(0, 25) + '...';
    } else {
      return '';
    }
  }

  public toggleAllFiltered(selected: boolean) {
    this.updatedByAllSelector = true;
    if (selected) {
      this.sortedProducts.forEach((prod: Product) => {
        prod.entities?.forEach((tank: Product) => {
          if (
            !this.checked.includes(
              'productChecked-' + prod.productId + '-' + tank.entityId
            )
          ) {
            this.checked.push(
              'productChecked-' + prod.productId + '-' + tank.entityId
            );
          }
        });
      });
    } else {
      const filteredEntities: number[] = [];
      this.sortedProducts.forEach((prod: Product) => {
        prod.entities?.forEach((tank: Product) => {
          filteredEntities.push(tank.entityId);
        });
      });
      this.checked = this.checked.filter(
        item => !filteredEntities.includes(+item.split('-')[2])
      );
    }
    ProductStore.updateRouteState({ generic: this.checked });
    setListItem('genericRoute', this.checked);
    this.$root.$emit('routeUpdated');
    this.$bvToast.toast(
      `${selected ? 'Added' : 'Removed'} all filtered products`,
      {
        title: 'Route updated',
        toaster: 'b-toaster-bottom-center',
        solid: true,
        append: false,
        to: '/generic-route-plan'
      }
    );
  }
}
