
import { Component, Prop, PropSync, Vue, Watch } from 'vue-property-decorator';

import { FuelMetricsTank, Product } from '@/store/models/Product';
import ProductStore from '@/store/modules/ProductStore';
import VolumeProgressBar from '@/components/items/VolumeProgressBar.vue';
import GenericTableList from '@/components/list/GenericTableList.vue';
import GenericCardList from '@/components/list/GenericCardList.vue';
import TableColumn from '@/store/models/TableColumn';
import CustomField from '@/store/models/CustomField';
import { getListItem, setListItem } from '@/utils/LocalStorageUtils';

@Component({
  components: {
    VolumeProgressBar,
    GenericTableList,
    GenericCardList
  }
})
export default class GenericListView extends Vue {
  @PropSync('items') syncedItems!: Product[]; // For now this is true for type, but we might want a new abstract interface for table items.
  @PropSync('sortKey') syncedSortKey!: string;
  @Prop() increasing!: boolean;
  @Prop() primaryKey!: string;
  @Prop() tableId!: string;
  @Prop() selectProdId!: number;
  @Prop() mapCollapse!: boolean;
  @Prop() isTable!: boolean;
  @Prop() loadingProducts!: boolean;
  @Prop() totalProducts!: number;
  @Prop() offset!: number;
  @Prop() perPageLimit!: number;

  public active = '';
  public topPosition = 553;
  public checked: string[] = [];
  public maxTank = 20000;
  public pagesList: (number | null)[] = [];
  public perPage = this.perPageLimit;

  mounted() {
    setTimeout(() => {
      this.getPosOfListView();
    }, 500);
    this.checked = ProductStore.currentRoute.generic ?? [];
    if (this.checked.length == 0) {
      this.checked = getListItem('genericRoute');
      ProductStore.updateRouteState({ generic: this.checked });
    }
  }

  created() {
    this.$root.$on('routeUpdated', () => {
      this.checked = [...(ProductStore.currentRoute.generic ?? [])] ?? [];
      if (this.checked.length == 0) {
        this.checked = getListItem('genericRoute');
        ProductStore.updateRouteState({ generic: this.checked });
      }
    });
    this.$root.$on('updateListSize', () => {
      this.getPosOfListView();
    });
    window.addEventListener('resize', this.getPosOfListView);
    document.addEventListener('mouseup', this.getPosOfListView);
  }
  destroyed() {
    window.removeEventListener('resize', this.getPosOfListView);
    document.removeEventListener('mouseup', this.getPosOfListView);
  }

  @Watch('mapCollapse')
  alterListHeight() {
    this.getPosOfListView();
  }

  @Watch('totalProducts')
  updatedProducts() {
    this.pageList();
  }

  @Watch('offset')
  updatedOffset() {
    this.pageList();
  }

  @Watch('perPage')
  perPageUpdated() {
    this.pageList();
    this.$emit('perPageUpdated', this.perPage);
  }

  @Watch('active')
  recalculateListHeight() {
    setTimeout(() => {
      this.getPosOfListView();
    }, 500);
  }

  @Watch('checked')
  routeUpdated() {
    const add =
      this.checked.filter(
        item => !ProductStore.currentRoute.generic?.includes(item)
      ) ?? [];
    const remove =
      ProductStore.currentRoute.generic?.filter(
        item => !this.checked.includes(item)
      ) ?? [];
    if (add.length != 0 || remove.length != 0) {
      const updatedValue =
        add.length != 0
          ? `${this.getNameOfProduct(add[0])} has been added to your route`
          : `${this.getNameOfProduct(
              remove[0]
            )} has been removed from your route`;
      ProductStore.updateRouteState({ generic: this.checked });
      setListItem('genericRoute', this.checked);
      this.$root.$emit('routeUpdated');

      this.$bvToast.toast(updatedValue, {
        title: 'Route updated',
        toaster: 'b-toaster-bottom-center',
        solid: true,
        append: false,
        to: '/generic-route-plan'
      });
    }
  }

  get upperBoundOffset() {
    return this.offset + this.perPageLimit > this.totalProducts
      ? this.totalProducts
      : this.offset + this.perPageLimit;
  }

  public pageList() {
    const pages = Math.ceil(this.totalProducts / this.perPage);
    const pagesList: (number | null)[] = [];
    if (pages <= 6) {
      let i;
      for (i = 1; i <= pages; i++) {
        pagesList.push(i);
      }

      this.pagesList = pagesList;
    } else {
      const currentPage = this.offset / this.perPage + 1;
      pagesList.push(1);

      let i;
      for (i = currentPage - 3; i <= currentPage + 3; i++) {
        if (i > 1 && i < pages) {
          if (i == currentPage - 3 && i != 2) {
            pagesList.push(null);
          } else if (i == currentPage + 3 && i != pages - 1) {
            pagesList.push(null);
          } else {
            pagesList.push(i);
          }
        }
      }
      pagesList.push(pages);
      this.pagesList = pagesList;
    }
  }

  public getPosOfListView() {
    if (this.$refs['listFuelContainer'] as HTMLElement) {
      this.topPosition =
        (this.$refs['listFuelContainer'] as HTMLElement).getBoundingClientRect()
          .top - (this.isTable ? 100 : 56);
    }
  }

  public getTopPos() {
    return `max-height:calc(100% - ${this.topPosition}px);`;
  }

  public getNameOfProduct(productChecked: string) {
    const productId = productChecked.split('-')[1];
    const entityId = productChecked.split('-')[2];
    const item = ProductStore.currentProducts?.filter(
      (product: Product) =>
        product.productId == +productId && product.entityId == +entityId
    );
    const name = item ? item[0].siteName : 'Fuel tank';
    const tank = item
      ? ` ${(item[0].metrics as FuelMetricsTank).fuelType} ${
          (item[0].metrics as FuelMetricsTank).nominalVolume
        }L`
      : '';
    return name + ' - ' + tank;
  }

  get sortedProducts() {
    const productsClone = [...this.syncedItems];
    const initProd = productsClone[0] as Product;
    const initEntity = initProd?.entities;
    if (initEntity) {
      const initMetrics = initEntity[0].metrics as FuelMetricsTank;
      this.maxTank = initMetrics.nominalVolume ?? 0;
    }
    productsClone.forEach((product: Product) => {
      product?.entities?.forEach((entity: Product) => {
        const capacity = (entity.metrics as FuelMetricsTank).nominalVolume;
        if (capacity > this.maxTank) {
          this.maxTank = capacity;
        }
      });
    });
    return productsClone;
  }

  public changeOffset(isPrevious: boolean, exactPage = 0) {
    if (exactPage != null) {
      (this.$refs['listFuelContainer'] as HTMLElement).scrollTo(0, 0);
      this.$emit('offsetChanged', isPrevious, exactPage - 1);
    }
  }

  public selectCard(id: string) {
    if (id == this.active) {
      this.active = '';
    } else {
      this.active = id;
    }
  }
  public didSelectCard(selectedItems: Product[], id: number, tankId: number) {
    this.$emit('card-selected', selectedItems, tankId);
    this.selectCard(id + '/' + tankId);
  }
}
