var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"containerInner border-left border-right"},[_c('BRow',{staticClass:"bg-grey mx-0 text-left",staticStyle:{"font-weight":"bold","position":"sticky","top":"0","z-index":"100"},attrs:{"id":"fuelTableHeader"}},[_c('BCol',{staticClass:"col40"},[_c('b-form-checkbox',{on:{"change":_vm.toggleAllFiltered},model:{value:(_vm.allFilteredSelected),callback:function ($$v) {_vm.allFilteredSelected=$$v},expression:"allFilteredSelected"}})],1),_c('BCol',{staticClass:"col280 p-0 pointerOnHover",staticStyle:{"padding-left":"8px !important"},on:{"click":function($event){return _vm.sortBy('siteName')}}},[_c('p',[_vm._v(" Site "),(_vm.sortByKey == 'siteName')?_c('BIcon',{staticClass:"ml-2 text-secondary",attrs:{"icon":_vm.increasing ? 'sort-up-alt' : 'sort-down'}}):_vm._e(),(_vm.sortByKey != 'siteName')?_c('BIcon',{staticClass:"ml-2 text-mediumGrey",attrs:{"icon":"chevron-expand"}}):_vm._e()],1)])],1),(_vm.sortedProducts || _vm.sortedProducts.length != 0)?_c('div',{staticClass:"fuelListView overflow-y-auto px-3"},[(_vm.sortedProducts.length == 0 && !_vm.loadingProducts)?_c('div',[_c('p',[_vm._v("No products to show")])]):_vm._e(),(_vm.loadingProducts)?_c('div',{staticClass:"text-center"},[_c('b-spinner',{staticClass:"mt-4 text-center",attrs:{"variant":"primary","id":"spinner3"}})],1):_vm._e(),_vm._l((_vm.sortedProducts),function(item){return _c('div',{key:item.productId,ref:"list",refInFor:true,attrs:{"border":""}},_vm._l((item.entities),function(tank,index){return _c('BRow',{key:index,staticClass:"productListEntity text-left border-bottom d-flex align-items-center align-self-center",class:item.productId + '/' + index === _vm.active
            ? 'bg-lightGrey'
            : 'bg-white',attrs:{"title":`${item.siteName} ${
            item.entities.length > 1
              ? tank.name
                ? `- ${tank.name}`
                : ''
              : ''
          }`,"id":'fuellist_' + item.productId,"align-v":"center"},on:{"click":function($event){return _vm.didSelectCard([item], item.productId, index)}}},[_c('BCol',{staticClass:"col40"},[_c('BFormCheckbox',{attrs:{"id":'productChecked-' + item.productId + '-' + tank.entityId,"name":'productChecked-' + item.productId + '-' + tank.entityId,"value":'productChecked-' + item.productId + '-' + tank.entityId,"unchecked-value":"not_accepted"},on:{"click":function($event){$event.stopPropagation();}},model:{value:(_vm.checked),callback:function ($$v) {_vm.checked=$$v},expression:"checked"}})],1),(index == 0)?_c('BCol',{staticClass:"col280 d-flex p-2"},[_c('p',{staticClass:"my-auto textOverflowEllipsis"},[_vm._v(" "+_vm._s(item.siteName ? item.siteName : item.productId)+" "),_c('span',{staticClass:"text-mediumGrey smallText ml-2"},[_vm._v(_vm._s(item.entities.length > 1 ? tank.name : ''))])])]):_vm._e(),(index != 0)?_c('BCol',{staticClass:"col280 d-flex p-0"},[_c('BRow',[_c('BCol',{staticClass:"col40"},[(index != item.entities.length - 1)?_c('div',{staticClass:"h-100",staticStyle:{"flex":"1 1 auto","display":"flex","flex-direction":"column","justify-content":"center","align-items":"flex-start"}},[_c('img',{attrs:{"src":require("../../assets/milkMiddleLinked.svg"),"height":"37px"}})]):_vm._e(),(
                  index == item.entities.length - 1 &&
                    item.entities.length != 1
                )?_c('div',{staticClass:"h-100",staticStyle:{"flex":"1 1 auto","display":"flex","flex-direction":"column","justify-content":"center","align-items":"flex-start"}},[_c('img',{attrs:{"src":require("../../assets/milkEndLinked.svg"),"height":"37px"}})]):_vm._e()]),_c('BCol',{staticClass:"my-auto"},[_c('p',{staticClass:"my-auto text-mediumGrey smallText ml-2"},[_vm._v(" "+_vm._s(_vm.getTankName(tank.name))+" ")])])],1)],1):_vm._e()],1)}),1)})],2):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }