
import { mixins } from 'vue-class-component';
import { Component, Prop, Vue } from 'vue-property-decorator';
import AppName from '../mixin/AppName.vue';

@Component
export default class VolumeProgressBar extends mixins(AppName) {
  @Prop() volume!: string;
  @Prop() capacity!: string;
  @Prop() maxVol!: string;

  public displayLocaleString(value: string) {
    if (value) {
      return Math.round(+value).toLocaleString();
    } else {
      return '0';
    }
  }
}
