
import AppName from '@/components/mixin/AppName.vue';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class DraggablePanel extends mixins(AppName) {
  @Prop({ default: true }) draggable!: boolean;
  public mPos: any;
  public colour = '#F6861F';

  mounted() {
    this.colour = this.getAppColour(false);
    const panel = document.getElementById('panel');
    if (panel && this.draggable) {
      panel.addEventListener('mousedown', this.mousedownFunc, false);
      document.addEventListener('mouseup', this.mouseupFunc, false);
    }
  }

  destroyed() {
    const panel = document.getElementById('panel');
    if (panel && this.draggable) {
      panel.removeEventListener('mousedown', this.mousedownFunc, false);
      document.removeEventListener('mouseup', this.mouseupFunc, false);
    }
  }

  public resize(e: any) {
    const dy = this.mPos - e.y;
    this.mPos = e.y;
    this.$emit('panelDragged', dy);
  }

  public mousedownFunc(e: any) {
    if (e.offsetY < 4) {
      this.mPos = e.y;
      document.addEventListener('mousemove', this.resize, false);
    }
  }

  public mouseupFunc(e: any) {
    document.removeEventListener('mousemove', this.resize, false);
  }
}
